import React, {useState} from "react";
import ReactDOM from "react-dom";
import {
	AdaptivityProvider,
	ConfigProvider,
	AppRoot,
	SplitLayout,
	SplitCol,
	View,
	Panel,
	Group,
	Cell,
	Div,
	Avatar,
	Button,
	Separator,
	Spacing,
	Title,
	SimpleCell,
	Text,
	TabbarItem,
	Tabbar,
} from "@vkontakte/vkui";
import "@vkontakte/vkui/dist/vkui.css";
import toast, {Toaster} from 'react-hot-toast';
import {isMobile} from 'react-device-detect';

import TextTransition, { presets } from "react-text-transition";

const avatar_blur = {
	// background: '#fdd6e4',
	width: 116,
	height: 116,
	margin: 6,
	position: 'absolute',
	borderRadius: '50%'
}

const chip_style = {
	// background: '#fefefe',
	color: '#555',
	borderRadius: 5,
	marginTop: 2,
	display: 'inline-block',
	padding: '3px 4px',
	fontSize: 14,
}


function redirecting_toast() {
	toast.loading('Loading external link...', {
		position: 'top-right',
		duration: 2000,
		style: {
			borderRadius: '6px',
			background: '#fdd6e4',
			color: '#121212',
			border: '1px solid #fbb7ce',
			padding: '4px 8px'
		},
		iconTheme: {
			primary: '#AD1457',
			secondary: '#fff',
		},
	});
}

function copy_toast() {
	toast.success('Copied', {
		position: 'top-right',
		duration: 1500,
		style: {
			borderRadius: '6px',
			background: '#fdd6e4',
			color: '#121212',
			border: '1px solid #fbb7ce',
			padding: '4px 8px'
		}
	});
}

var activePanel = window.location.hash ? window.location.hash.slice(1) : "about";

const git = <lord-icon
    src="https://assets7.lottiefiles.com/packages/lf20_cwqf5i6h.json"
    trigger="loop"
    style={{width: "28px", height: "28px"}}>
</lord-icon>;
const tg = <lord-icon
	src="https://assets10.lottiefiles.com/packages/lf20_pqnhnube.json"
	trigger="loop"
	style={{width: "22px", height: "22px", padding: "3px"}}>
</lord-icon>;
const discord = <lord-icon
	src="https://assets1.lottiefiles.com/packages/lf20_ygq6u6mb.json"
	trigger="loop"
	style={{width: "22px", height: "22px", padding: "3px"}}>
</lord-icon>;
const vk = <lord-icon
	src="https://assets7.lottiefiles.com/packages/lf20_XOIztQ.json"
	trigger="loop"
	style={{width: "22px", height: "22px", padding: "3px"}}>
</lord-icon>;

const TEXTS = [
	"көлкеле аватарлар",
	"программирование",
	"психология",
	"ачык-чыганак",
	"сөйләшү",
	"аниме",
];


const VkCard = () => {
	const [state, setState] = useState(activePanel);
	const [index, setIndex] = React.useState(0);

	React.useEffect(() => {
		const intervalId = setInterval(() =>
			setIndex(index => index + 1),
			2000
		);
		return () => clearTimeout(intervalId);
	}, []);

	const ss = (s) => {
		return () => {
			window.location.hash = s;
			setState(s);
		}
	}

	return (
		<ConfigProvider scheme="space_gray">
			<AdaptivityProvider>
				<AppRoot>
					{isMobile ? <Tabbar style={{position: 'fixed', zIndex: 100}}>
									<TabbarItem
										onClick={ss('about')}
										selected={state === "about"}
										data-story="about"
										text="Минем хакта миңа"
									>
										<lord-icon
										    src="https://cdn.lordicon.com/ajkxzzfb.json"
										    trigger="loop"
										    style={{width: '34px', height: '34px', marginRight: '6px'}}>
										</lord-icon>
									</TabbarItem>
									<TabbarItem
										onClick={ss('donate')}
										selected={state === "donate"}
										data-story="donate"
										text="Корбан итү"
									>
										<lord-icon
										    src="https://cdn.lordicon.com/liqouopv.json"
										    trigger="loop"
										    style={{width: '34px', height: '34px', marginRight: '6px'}}>
										</lord-icon>
									</TabbarItem>
									<TabbarItem
										onClick={ss('projects')}
										selected={state === "projects"}
										data-story="projects"
										text="Проектлар"
									>
										<lord-icon
										    src="https://cdn.lordicon.com/ibalslef.json"
										    trigger="loop"
										    style={{width: '34px', height: '34px', marginRight: '6px'}}>
										</lord-icon>
									</TabbarItem>
									<TabbarItem
										onClick={ss('contact')}
										selected={state === "contact"}
										data-story="contact"
										text="Элемтә"
									>
										<lord-icon
										    src="https://cdn.lordicon.com/flvisirw.json"
										    trigger="loop"
										    style={{width: '34px', height: '34px', marginRight: '6px'}}>
										</lord-icon>
									</TabbarItem>
								</Tabbar> : ''}
					<SplitLayout>
						<SplitCol width={280} maxWidth={280} style={isMobile ? {display: 'none'} : {marginLeft: 16}}>
							<View activePanel="menu">
								<Panel id="menu">
									<Group>
										<Cell
											data-story="about"
											before=<lord-icon
											    src="https://cdn.lordicon.com/ajkxzzfb.json"
											    trigger="loop"
											    style={{width: '34px', height: '34px', marginRight: '6px'}}>
											</lord-icon>
											onClick={ss('about')}
											disabled={state === 'about' ? true : false}
											style={state === 'about' ? {border: '1px solid #fdd6e4', borderRadius: 8} : {}}>
											<span>Минем хакта миңа</span>
										</Cell>
										<Cell
											data-story="donate"
											before=<lord-icon
											    src="https://cdn.lordicon.com/liqouopv.json"
											    trigger="loop"
											    style={{width: '34px', height: '34px', marginRight: '6px'}}>
											</lord-icon>
											onClick={ss('donate')}
											disabled={state === 'donate' ? true : false}
											style={state === 'donate' ? {border: '1px solid #fdd6e4', borderRadius: 8} : {}}>
											<span>Корбан итү</span>
										</Cell>
										<Cell
											data-story="projects"
											before=<lord-icon
											    src="https://cdn.lordicon.com/ibalslef.json"
											    trigger="loop"
											    style={{width: '34px', height: '34px', marginRight: '6px'}}>
											</lord-icon>
											onClick={ss('projects')}
											disabled={state === 'projects' ? true : false}
											style={state === 'projects' ? {border: '1px solid #fdd6e4', borderRadius: 8} : {}}>
											<span>Проектлар</span>
										</Cell>
										<Cell
											data-story="contact"
											before=<lord-icon
											    src="https://cdn.lordicon.com/flvisirw.json"
											    trigger="loop"
											    style={{width: '34px', height: '34px', marginRight: '6px'}}>
											</lord-icon>
											onClick={ss('contact')}
											disabled={state === 'contact' ? true : false}
											style={state === 'contact' ? {border: '1px solid #fdd6e4', borderRadius: 8} : {}}>
											<span>Элемтә</span>
										</Cell>
									</Group>
								</Panel>
							</View>
						</SplitCol>
						<SplitCol style={isMobile ? {} : {marginLeft: 16, marginRight: 16}}>
							<View activePanel={state}>
								<Panel id="about">
									<Group>
										<Div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center', margin: 10}}>
											<span>
												<div style={{animation: 'blurrer .8s cubic-bezier(.46,0,.58,.86) 1s forwards'}}>
													<div style={avatar_blur} className="rainbow"></div>
												</div>
												<Avatar style={{border: 'none'}} id="smart_ava" src="/photo.png" size={128}/>
											</span>
											<h1 className="vkuiTitle vkuiTitle--android Title Title--android vkuiTitle--w-semibold Title--w-semibold vkuiTitle--l-1 Title--l-1"
												style={{marginTop: 24}}
												id="smart_name">
												Минем исемем Даниил Газизуллин
											</h1>
											<Spacing size={16}/>
											<h2 className="vkuiTitle vkuiTitle--android Title Title--android vkuiTitle--w-semibold Title--w-semibold vkuiTitle--l-2 Title--l-2"
												style={{margin: 0}}>
												17-җәй <span style={{color: '#ff54dd'}}>мәгълүмати куркынычсызлык</span> энтузиасты, <br></br><span style={{color: '#ffdd54'}}>Python</span>, <span style={{color: '#33699a'}}>C++</span>, <span style={{color: '#ff6600'}}>Web</span> &lt;Программист/&gt;
											</h2>
											<Spacing size={16}/>
											<b style={{fontSize: 18}}><lord-icon
													src="https://cdn.lordicon.com/oaflahpk.json"
													trigger="loop"
													style={{width: '28px', height: '28px', marginRight: '4px'}}>
												</lord-icon> Иннополис, Россия</b>
											<Spacing size={16}/>
											<span style={{fontSize: 22}}>Мин&nbsp; 
												<TextTransition
												text={ TEXTS[index % TEXTS.length] }
												язConfig={presets.stiff}
												style={{fontSize: 22}}
												overflow
												inline/>&nbsp;яратам</span>
											<Spacing size={16} />
											<Separator/>
											<Spacing size={16}/>
											<Button
													before={git}
													appearance='neutral'
													size='m'
													style={{background: "#fefefe", color: "#121212", margin: 8}}
													onClick={() => {redirecting_toast(); setTimeout(() => {window.open("https://github.com/hikariatama", "_blank");}, 1000);}}>
													hikariatama
											</Button>
										</Div>
									</Group>
								</Panel>



								<Panel id="donate">
									<Group>
										<Div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center', margin: 10}}>
											<Title>
												Рәхмәт әйтүнең иң яхшы ысулы
											</Title>
											<Spacing size={16} />
											<Div>
												<Button
													before=<img alt="Some sort of icon" src="/sber.svg" height={18}/>
													appearance='accent'
													size='l'
													style={{background: "rgb(22, 112, 52)", color: "#fff", margin: 8}}
													onClick={() => {copy_toast(); navigator.clipboard.writeText("4274320059444789")}}>
													Сбербанк
												</Button>

												<Button
													before=<img alt="Some sort of icon" src="/tinkoff.svg" height={18}/>
													appearance='accent'
													size='l'
													style={{background: "#fff", color: "#000", margin: 8}}
													onClick={() => {redirecting_toast(); setTimeout(() => {window.open("https://www.tinkoff.ru/rm/gazizullin.daniil2/A54ce52313", "_blank");}, 1000);}}>
													Тинькофф
												</Button>

												<Button
													before=<img alt="Some sort of icon" src="/yoomoney.svg" height={18}/>
													appearance='accent'
													size='l'
													style={{background: "#0D47A1", color: "#fff", margin: 8}}
													onClick={() => {redirecting_toast(); setTimeout(() => {window.open("https://yoomoney.ru/to/4100116399134842", "_blank");}, 1000);}}>
													ЮМани
												</Button>

												<Button
													before=<img alt="Some sort of icon" src="/cryptobot.png" height={18}/>
													appearance='accent'
													size='l'
													style={{background: "#42a4de", color: "#fff", margin: 8}}
													onClick={() => {redirecting_toast(); setTimeout(() => {window.open("http://t.me/CryptoБот?start=IVrBqmvlBlnt", "_blank");}, 1000);}}>
													CryptoБот
												</Button>

												<Spacing size={16} />

												<Separator/>

												<Spacing size={16} />

												<Button
													before={git}
													appearance='accent'
													size='l'
													style={{background: "#fefefe", color: "#121212", margin: 8}}
													onClick={() => {redirecting_toast(); setTimeout(() => {window.open("https://github.com/hikariatama", "_blank");}, 1000);}}>
													Гитхаб йолдыз бир
												</Button>
											</Div>
										</Div>
									</Group>
								</Panel>


								<Panel id="projects">
									<Group>
										<Div style={isMobile ? {padding: 4} : {padding: 16}}>
											<Title style={{textAlign: 'center'}}>Минем проектлар</Title>
											<Spacing size={32} />
											<div style={isMobile? {display: "flex", flexDirection: "column"} : {display: "flex"}}>
											<SplitCol
												animate={isMobile}
												width={isMobile ? "100%" : "50%"}>
												<SimpleCell multiline>
													<Spacing size={4} />
													<Title style={{display: 'flex'}}><Avatar size={34} src='https://img.icons8.com/emoji/48/null/bear-emoji.png'/>&nbsp;Сибирский Ленинец II</Title>
													<span style={chip_style}>2023, кыш</span>
													<Spacing size={4} />
													<Text>Укыту программалау теленең адымлы интерпретаторы Сибирский Ленинец II</Text>
													<Spacing size={10} />
													<div style={{display: "flex"}}>
														<Button
															onClick={() => {redirecting_toast(); setTimeout(() => {window.open("https://github.com/hikariatama/leninec", "_blank");}, 1000);}}
															appearance='accent'
															before={git}>Чыганак</Button>
														<Button
															onClick={() => {redirecting_toast(); setTimeout(() => {window.open("https://leninec.hikariatama.ru/", "_blank");}, 1000);}}
															appearance='positive'
															size='m'
															style={{marginLeft: 8}}>Сайт</Button>
														<Button
															onClick={() => {redirecting_toast(); setTimeout(() => {window.open("https://github.com/teamteamdev/ugractf-2023-quals/tree/master/tasks/qrec", "_blank");}, 1000);}}
															appearance='accent'
															before={git}
															size='m'
															style={{marginLeft: 8}}>Төп нөсхә</Button>
														
													</div>
												</SimpleCell>

												<SimpleCell multiline>
													<Spacing size={4} />
													<Title style={{display: 'flex'}}><Avatar size={34} src='https://0x0.st/oF4n.jpg'/>&nbsp;Acunetix SDK</Title>
													<span style={chip_style}>2023, кыш</span>
													<Spacing size={4} />
													<Text>Acunetix өчен Python SDK</Text>
													<Spacing size={10} />
													<div style={{display: "flex"}}>
														<Button
															onClick={() => {redirecting_toast(); setTimeout(() => {window.open("https://github.com/hikariatama/acunetix", "_blank");}, 1000);}}
															appearance='accent'
															before={git}
															style={{marginLeft: 8}}>Чыганак</Button>
													</div>
												</SimpleCell>

												<SimpleCell multiline>
													<Spacing size={4} />
													<Title style={{display: 'flex'}}><Avatar size={34} src='https://i.imgur.com/DzxxMtr.jpeg'/>&nbsp;ZLT shop</Title>
													<span style={chip_style}>2022, җәй</span>
													<Spacing size={4} />
													<Text>Гади ярдәмче бот</Text>
													<Spacing size={10} />
													<div style={{display: "flex"}}>
														<Button
															onClick={() => {redirecting_toast(); setTimeout(() => {window.open("https://t.me/zlt_shop_bot/", "_blank");}, 1000);}}
															appearance='positive'
															before={tg}
															style={{marginLeft: 8}}>Күрсәтү</Button>
													</div>
												</SimpleCell>

												<SimpleCell multiline>
													<Spacing size={4} />
													<Title style={{display: 'flex'}}><Avatar size={34} src='https://i.imgur.com/hZIyI7v.jpeg'/>&nbsp;Hikka</Title>
													<span style={chip_style}>2022, яз</span>
													<Spacing size={4} />
													<Text>Иң яңа һәм яңартылган махсус Телеграм бот</Text>
													<Spacing size={10} />
													<div style={{display: "flex"}}>
														<Button
															onClick={() => {redirecting_toast(); setTimeout(() => {window.open("https://github.com/hikariatama/Hikka/", "_blank");}, 1000);}}
															appearance='accent'
															before={git}
															size='m'>Чыганак</Button>
														<Button
															onClick={() => {redirecting_toast(); setTimeout(() => {window.open("https://t.me/hikka_talks/", "_blank");}, 1000);}}
															appearance='accent'
															mode="outline"
															size='m'
															before={tg}
															style={{marginLeft: 8}}>Төркем</Button>
													</div>
												</SimpleCell>

												<SimpleCell multiline>
													<Spacing size={4} />
													<Title style={{display: 'flex'}}><Avatar size={34} src='https://i.imgur.com/t5jY7Ed.jpeg'></Avatar>&nbsp;PsychologyБот</Title>
													<span style={chip_style}>beta</span>
													<Spacing size={4} />
													<Text>Белгечләр белән психологик консультацияләр</Text>
													<Spacing size={10} />
													<Button
														appearance='positive'
														size='m'
														before={tg}
														onClick={() => {redirecting_toast(); setTimeout(() => {window.open("tg://resolve?domain=annayaschenko_bot", "_blank");}, 1000);}}>Бот</Button>
												</SimpleCell>

												<Spacing size={16} />

												<SimpleCell multiline>
													<Spacing size={4} />
													<Title style={{display: 'flex'}}><Avatar size={34} src='https://i.imgur.com/qrLu0f6.jpeg'/>&nbsp;HikariChat</Title>
													<span style={chip_style}>2022, кыш</span>
													<Spacing size={4} />
													<Text>Иң зур модуль администраторлар өчен мәҗбүри</Text>
													<Spacing size={10} />
													<div style={{display: "flex"}}>
														<Button
															onClick={() => {redirecting_toast(); setTimeout(() => {window.open("https://mods.hikariatama.ru/view/hikarichat.py", "_blank");}, 1000);}}
															appearance='accent'
															before={git}
															size='m'>Чыганак</Button>

														<Button
															onClick={() => {redirecting_toast(); setTimeout(() => {window.open("tg://resolve?domain=hikaripay_bot", "_blank");}, 1000);}}
															appearance='accent'
															mode="outline"
															before={tg}
															style={{marginLeft: 8}}
															size='m'>Алу</Button>
													</div>
												</SimpleCell>

												<Spacing size={16} />

												<SimpleCell multiline>
													<Spacing size={4} />
													<Title style={{display: 'flex'}}><Avatar size={34} src='https://i.imgur.com/0V1pYzp.jpeg'></Avatar>&nbsp;Informatics</Title>
													<span style={chip_style}>2022, кыш</span>
													<Spacing size={4} />
													<Text>Минем карарларым informatics.msk.ru</Text>
													<Spacing size={10} />
													<Button
														onClick={() => {redirecting_toast(); setTimeout(() => {window.open("https://github.com/hikariatama/informatics", "_blank");}, 1000);}}
														appearance='positive'
														size='m'>Сайт</Button>
												</SimpleCell>

												<Spacing size={16} />

												<SimpleCell multiline>
													<Spacing size={4} />
													<Title style={{display: 'flex'}}><Avatar size={34} src='https://img.icons8.com/dusk/64/000000/postman-api.png'/>&nbsp;HikariApi</Title>
													<span style={chip_style}>2022, кыш</span>
													<Spacing size={4} />
													<Text>Мин API</Text>
													<Spacing size={10} />
													<div style={{display: "flex"}}>
														<Button
															onClick={() => {redirecting_toast(); setTimeout(() => {window.open("https://api.hikariatama.ru", "_blank");}, 1000);}}
															appearance='positive'
															size='m'>Ярдәм</Button>
														<Button
															onClick={() => {redirecting_toast(); setTimeout(() => {window.open("tg://resolve?domain=innoapi_auth_bot", "_blank");}, 1000);}}
															appearance='accent'
															mode="outline"
															size='m'
															before={tg}
															style={{marginLeft: 8}}>Ачкыч алу</Button>
													</div>
												</SimpleCell>

												<Spacing size={16} />

												<SimpleCell multiline>
													<Spacing size={4} />
													<Title style={{display: 'flex'}}><Avatar size={34} src='https://i.imgur.com/lmvv3LU.png'/>&nbsp;GeekTG</Title>
													<span style={chip_style}>2021, кыш</span>
													<Spacing size={4} />
													<Text>Салкын махсус бот</Text>
													<Spacing size={10} />
													<div style={{display: "flex"}}>
														<Button
															onClick={() => {redirecting_toast(); setTimeout(() => {window.open("https://github.com/GeekTG/Friendly-Телеграм/", "_blank");}, 1000);}}
															appearance='accent'
															before={git}
															size='m'>Чыганак</Button>
														<Button
															onClick={() => {redirecting_toast(); setTimeout(() => {window.open("https://ftg.geektg.ml/", "_blank");}, 1000);}}
															appearance='accent'
															mode="outline"
															size='m'
															style={{marginLeft: 8}}>Ярдәм</Button>
													</div>
												</SimpleCell>

												<Spacing size={16} />

												<SimpleCell multiline>
													<Spacing size={4} />
													<Title style={{display: 'flex'}}><Avatar size={34} src='https://i.imgur.com/weTqHaJ.png'/>&nbsp;Telethon-Mod</Title>
													<span style={chip_style}>2021, кыш</span>
													<Spacing size={4} />
													<Text>Яхшыртылган Telethon v1. Соңгы катламнар</Text>
													<Spacing size={10} />
													<Button
														onClick={() => {redirecting_toast(); setTimeout(() => {window.open("https://github.com/GeekTG/Telethon/", "_blank");}, 1000);}}
														appearance='accent'
														before={git}
														size='m'>Чыганак</Button>
												</SimpleCell>

												<Spacing size={16} />

												<SimpleCell multiline>
													<Spacing size={4} />
													<Title style={{display: 'flex'}}><Avatar size={34} src='https://i.imgur.com/vqn3EQ9.jpeg'/>&nbsp;HiakriMods Database</Title>
													<span style={chip_style}>2021, кыш</span>
													<Spacing size={4} />
													<Text>Расланган модульләр коллекциясе</Text>
													<Spacing size={10} />
													<div style={{display: "flex"}}>
														<Button
															onClick={() => {redirecting_toast(); setTimeout(() => {window.open("https://github.com/hikariatama/host/", "_blank");}, 1000);}}
															appearance='accent'
															before={git}
															size='m'>Чыганак</Button>
														<Button
															onClick={() => {redirecting_toast(); setTimeout(() => {window.open("tg://resolve?domain=hikarimods_database", "_blank");}, 1000);}}
															appearance='accent'
															mode="outline"
															size='m'
															before={tg}
															style={{marginLeft: 8}}>Телеграм</Button>
													</div>
												</SimpleCell>

												<Spacing size={16} />

												<SimpleCell multiline>
													<Spacing size={4} />
													<Title style={{display: 'flex'}}><Avatar size={34} src='https://i.imgur.com/UbXAleN.jpeg'/>&nbsp;HikariMods</Title>
													<span style={chip_style}>2021, көз</span>
													<Spacing size={4} />
													<Text>Минем Hikka модульләре</Text>
													<Spacing size={10} />
													<div style={{display: "flex"}}>
														<Button
															onClick={() => {redirecting_toast(); setTimeout(() => {window.open("https://mods.hikariatama.ru/", "_blank");}, 1000);}}
															appearance='positive'
															size='m'>Каталог</Button>
														<Button
															onClick={() => {redirecting_toast(); setTimeout(() => {window.open("tg://resolve?domain=hikarimods", "_blank");}, 1000);}}
															appearance='accent'
															mode="outline"
															size='m'
															before={tg}
															style={{marginLeft: 8}}>Телеграм</Button>
													</div>
												</SimpleCell>

												<Spacing size={16} />

												<SimpleCell multiline>
													<Spacing size={4} />
													<Title style={{display: 'flex'}}><Avatar size={34} src='https://i.imgur.com/OrCVZRZ.jpeg'/>&nbsp;InnoScheduler</Title>
													<span style={chip_style}>2021, яз</span>
													<Spacing size={4} />
													<Text>CULTURE OUTSIDE BORDERSДА катнашкан Проект</Text>
													<Spacing size={10} />
													<Button
														onClick={() => {redirecting_toast(); setTimeout(() => {window.open("https://github.com/hikariatama/hikariatama/blob/master/archive_innoscheduler.py", "_blank");}, 1000);}}
														appearance='accent'
														before={git}
														size='m'>Чыганак</Button>
												</SimpleCell>

												<Spacing size={16} />

												<SimpleCell multiline>
													<Spacing size={4} />
													<Title style={{display: 'flex'}}><Avatar size={34} src='https://i.imgur.com/nxBHfQ4.jpeg'/>&nbsp;InnoStudy</Title>
													<span style={chip_style}>2020, кыш</span>
													<Spacing size={4} />
													<Text>Электрон уку өчен электрон китап</Text>
													<Spacing size={10} />
													<Button
														onClick={() => {redirecting_toast(); setTimeout(() => {window.open("https://study.hikariatama.ru", "_blank");}, 1000);}}
														appearance='positive'
														size='m'>Сайт</Button>
												</SimpleCell>

												<Spacing size={16} />

												<SimpleCell multiline>
													<Spacing size={4} />
													<Title style={{display: 'flex'}}><Avatar size={34} src='https://i.imgur.com/0rJo62I.png'/>&nbsp;Moderator</Title>
													<span style={chip_style}>2020, кыш</span>
													<Spacing size={4} />
													<Text>Зур функциональ Чат модераторы</Text>
													<Spacing size={10} />
													<Button
														onClick={() => {redirecting_toast(); setTimeout(() => {window.open("https://github.com/hikariatama/hikariatama/blob/master/archive_moderator.py", "_blank");}, 1000);}}
														appearance='accent'
														before={git}
														size='m'>Чыганак</Button>
												</SimpleCell>

												<Spacing size={16} />

												<SimpleCell multiline>
													<Spacing size={4} />
													<Title style={{display: 'flex'}}><Avatar size={34} src='https://i.imgur.com/ZBZ3nog.jpeg'/>&nbsp;ProstoLid 3.0</Title>
													<span style={chip_style}>2020, кыш</span>
													<Spacing size={4} />
													<Text>Фрилансерлар өчен эш эзләү</Text>
													<Spacing size={10} />
													<div style={{display: "flex"}}>
														<Button
															onClick={() => {redirecting_toast(); setTimeout(() => {window.open("https://hikariatama.ru", "_blank");}, 1000);}}
															appearance='positive'
															size='m'>Күрсәтү</Button>
														<Button
															onClick={() => {redirecting_toast(); setTimeout(() => {window.open("tg://resolve?domain=hikariatama", "_blank");}, 1000);}}
															appearance='accent'
															mode="outline"
															size='m'
															before={tg}
															style={{marginLeft: 8}}>Алу</Button>
													</div>
												</SimpleCell>

												<Spacing size={16} />

												<SimpleCell multiline>
													<Spacing size={4} />
													<Title style={{display: 'flex'}}><Avatar size={34} src='https://i.imgur.com/qqMN8hT.jpeg'/>&nbsp;ProstoLid 2.0</Title>
													<span style={chip_style}>2020, кыш</span>
													<Spacing size={4} />
													<Text>Фрилансерлар өчен эш эзләү</Text>
													<Spacing size={10} />
													<div style={{display: "flex"}}>
														<Button
															onClick={() => {redirecting_toast(); setTimeout(() => {window.open("tg://resolve?domain=prostolid_new", "_blank");}, 1000);}}
															appearance='positive'
															size='m'
															before={tg}>Түләүсез</Button>
														<Button
															onClick={() => {redirecting_toast(); setTimeout(() => {window.open("tg://resolve?domain=prostolid_bot", "_blank");}, 1000);}}
															appearance='accent'
															mode="outline"
															size='m'
															before={tg}
															style={{marginLeft: 8}}>Сынау чоры</Button>
														<Button
															onClick={() => {redirecting_toast(); setTimeout(() => {window.open("tg://resolve?domain=prostolid_bot", "_blank");}, 1000);}}
															appearance='accent'
															mode="outline"
															size='m'
															before={tg}
															style={{marginLeft: 8}}>Алу</Button>
													</div>
												</SimpleCell>

												<Spacing size={16} />

												<SimpleCell multiline>
													<Spacing size={4} />
													<Title style={{display: 'flex'}}><Avatar size={34} src='https://i.imgur.com/iaJCEEv.jpeg'/>&nbsp;Acunetix Automate</Title>
													<span style={chip_style}>[snippet] 2020, яз</span>
													<Spacing size={4} />
													<Text>Acunetixга автомат рәвештә сайтлар өстәү</Text>
													<Spacing size={10} />
													<Button
														onClick={() => {redirecting_toast(); setTimeout(() => {window.open("https://github.com/hikariatama/hikariatama/blob/master/archive_acunetix_automate.py", "_blank");}, 1000);}}
														appearance='accent'
														before={git}
														size='m'>Чыганак</Button>
												</SimpleCell>

												<Spacing size={16} />

												<SimpleCell multiline>
													<Spacing size={4} />
													<Title style={{display: 'flex'}}><Avatar size={34} src='https://i.imgur.com/iaJCEEv.jpeg'/>&nbsp;Acunetix Kicker</Title>
													<span style={chip_style}>[snippet] 2020, яз</span>
													<Spacing size={4} />
													<Text>Acunetix өчен шакыган</Text>
													<Spacing size={10} />
													<Button
														onClick={() => {redirecting_toast(); setTimeout(() => {window.open("https://github.com/hikariatama/hikariatama/blob/master/archive_acunetix_kicker.py", "_blank");}, 1000);}}
														appearance='accent'
														before={git}
														size='m'>Чыганак</Button>
												</SimpleCell>
												</SplitCol>

												<Spacing size={16} />

												<SplitCol
												animate={isMobile}
												width={isMobile ? "100%" : "50%"}>
												<SimpleCell multiline>
													<Spacing size={4} />
													<Title style={{display: 'flex'}}><Avatar size={34} src='https://0x0.st/oF4n.jpg'/>&nbsp;Acunetix Бот</Title>
													<span style={chip_style}>2023, кыш</span>
													<Spacing size={4} />
													<Text>Телеграм бот тирәли төргәк Acunetix Web Vulnerability Scanner</Text>
													<Spacing size={10} />
													<div style={{display: "flex"}}>
														<Button
															onClick={() => {redirecting_toast(); setTimeout(() => {window.open("https://t.me/acunetix_bot", "_blank");}, 1000);}}
															appearance='positive'
															before={tg}
															style={{marginLeft: 8}}>Күрсәтү</Button>
													</div>
												</SimpleCell>

												<SimpleCell multiline>
													<Spacing size={4} />
													<Title style={{display: 'flex'}}><Avatar size={34} src='https://i.imgur.com/MqcQevp.jpeg'/>&nbsp;NewEra</Title>
													<span style={chip_style}>2019, яз</span>
													<Spacing size={4} />
													<Text>Хәзер үле проект өчен Бот</Text>
													<Spacing size={10} />
													<Button
														onClick={() => {redirecting_toast(); setTimeout(() => {window.open("https://github.com/hikariatama/hikariatama/blob/master/archive_newera.py", "_blank");}, 1000);}}
														appearance='accent'
														before={git}
														size='m'>Чыганак</Button>
												</SimpleCell>

												<Spacing size={16} />

												<SimpleCell multiline>
													<Spacing size={4} />
													<Title style={{display: 'flex'}}><Avatar size={34} src='https://i.imgur.com/Ex9U9P2.jpeg'/>&nbsp;Hostelinn</Title>
													<span style={chip_style}>2019, көз</span>
													<Spacing size={4} />
													<Text>Иннополис кунакханәсе өчен Телеграм бот һәм сайт</Text>
													<Spacing size={10} />
													<div style={{display: "flex"}}>
														<Button
															onClick={() => {redirecting_toast(); setTimeout(() => {window.open("https://hostel-inn.ru/", "_blank");}, 1000);}}
															appearance='positive'
															size='m'>Сайт</Button>
														<Button
															onClick={() => {redirecting_toast(); setTimeout(() => {window.open("tg://resolve?domain=hostelinnotify_bot", "_blank");}, 1000);}}
															appearance='accent'
															mode="outline"
															size='m'
															before={tg}
															style={{marginLeft: 8}}>Бот</Button>
													</div>
												</SimpleCell>


												<Spacing size={16} />

												<SimpleCell multiline>
													<Spacing size={4} />
													<Title style={{display: 'flex'}}><Avatar size={34} src='https://i.imgur.com/Qjsj9nV.jpeg'/>&nbsp;CleanMagic</Title>
													<span style={chip_style}>2019, көз</span>
													<Spacing size={4} />
													<Text>Иннополис клининг үзәге өчен Телеграм бот</Text>
													<Spacing size={10} />
													<Button
														onClick={() => {redirecting_toast(); setTimeout(() => {window.open("tg://resolve?domain=cleanmagic_bot", "_blank");}, 1000);}}
														appearance='positive'
														before={tg}
														size='m'>Бот</Button>
												</SimpleCell>

												<Spacing size={16} />

												<SimpleCell multiline>
													<Spacing size={4} />
													<Title style={{display: 'flex'}}><Avatar size={34} src='https://i.imgur.com/WjeAvAc.jpeg'/>&nbsp;AnyWhim</Title>
													<span style={chip_style}>2019, көз</span>
													<Spacing size={4} />
													<Text>Казан химик чистарту өчен Телеграм бот</Text>
													<Spacing size={10} />
													<div style={{display: "flex"}}>
														<Button
															onClick={() => {redirecting_toast(); setTimeout(() => {window.open("https://github.com/hikariatama/hikariatama/blob/master/archive_anywhim.py", "_blank");}, 1000);}}
															appearance='accent'
															before={git}
															size='m'>Чыганак</Button>

														<Button
															onClick={() => {redirecting_toast(); setTimeout(() => {window.open("tg://resolve?domain=anywhim_bot", "_blank");}, 1000);}}
															appearance='accent'
															mode="outline"
															size='m'
															before={tg}
															style={{marginLeft: 8}}>Күрсәтү</Button>
													</div>
												</SimpleCell>

												<Spacing size={16} />

												<SimpleCell multiline>
													<Spacing size={4} />
													<Title style={{display: 'flex'}}><Avatar size={34} src='https://i.imgur.com/jBRBjQE.jpeg'/>&nbsp;MuineHelper</Title>
													<span style={chip_style}>2019, көз</span>
													<Spacing size={4} />
													<Text>Телеграм бот дию 'Real Muine!'</Text>
													<Spacing size={10} />
													<Button
														onClick={() => {redirecting_toast(); setTimeout(() => {window.open("tg://resolve?domain=muinehelperbot", "_blank");}, 1000);}}
														appearance='positive'
														before={tg}
														size='m'>Бот</Button>
												</SimpleCell>

												<Spacing size={16} />

												<SimpleCell multiline>
													<Spacing size={4} />
													<Title style={{display: 'flex'}}><Avatar size={34} src='https://i.imgur.com/vGgDVos.jpeg'/>&nbsp;RomaDonat</Title>
													<span style={chip_style}>2019, көз</span>
													<Spacing size={4} />
													<Text>Уен боты өчен заказлар планлаштыручы</Text>
													<Spacing size={10} />
													<Button
														onClick={() => {redirecting_toast(); setTimeout(() => {window.open("https://github.com/hikariatama/hikariatama/blob/master/archive_roma_donate_bot.py", "_blank");}, 1000);}}
														appearance='accent'
														before={git}
														size='m'>Чыганак</Button>
												</SimpleCell>

												<Spacing size={16} />

												<SimpleCell multiline>
													<Spacing size={4} />
													<Title style={{display: 'flex'}}><Avatar size={34} src='https://i.imgur.com/qppXz8v.jpeg'/>&nbsp;iChistoБот</Title>
													<span style={chip_style}>2018, җәй</span>
													<Spacing size={4} />
													<Text>Казан клининг үзәге өчен Телеграм бот</Text>
													<Spacing size={10} />
													<div style={{display: "flex"}}>
														<Button
															onClick={() => {redirecting_toast(); setTimeout(() => {window.open("https://github.com/hikariatama/hikariatama/blob/master/archive_ichisto_bot.py", "_blank");}, 1000);}}
															appearance='accent'
															before={git}
															size='m'>Чыганак</Button>
														<Button
															onClick={() => {redirecting_toast(); setTimeout(() => {window.open("tg://resolve?domain=ichisto_bot", "_blank");}, 1000);}}
															appearance='accent'
															mode="outline"
															size='m'
															before={tg}
															style={{marginLeft: 8}}>Күрсәтү</Button>
													</div>
												</SimpleCell>

												<Spacing size={16} />

												<SimpleCell multiline>
													<Spacing size={4} />
													<Title style={{display: 'flex'}}><Avatar size={34} src='https://i.imgur.com/j9YsIRw.jpeg'/>&nbsp;InnoContest</Title>
													<span style={chip_style}>2018, җәй</span>
													<Spacing size={4} />
													<Text>Бик иске, ләкин матур проект</Text>
													<Spacing size={10} />
													<Button
														onClick={() => {redirecting_toast(); setTimeout(() => {window.open("https://contest.hikariatama.ru/begin/?contest_id=48632285", "_blank");}, 1000);}}
														appearance='positive'
														size='m'>Күрсәтү</Button>
												</SimpleCell>


												<Spacing size={16} />

												<SimpleCell multiline>
													<Spacing size={4} />
													<Title style={{display: 'flex'}}><Avatar size={34} src='https://i.imgur.com/D5vqpd8.jpeg'/>&nbsp;InnoDetective</Title>
													<span style={chip_style}>2018, яз</span>
													<Spacing size={4} />
													<Text>Бәхетле тәмамланырга мөмкин?</Text>
													<Spacing size={10} />
													<Button
														onClick={() => {redirecting_toast(); setTimeout(() => {window.open("https://detective.hikariatama.ru", "_blank");}, 1000);}}
														appearance='positive'
														size='m'>Күрсәтү</Button>
												</SimpleCell>

												<Spacing size={48} />
												<Separator/>
												<Spacing size={16} />

												<Title style={{textAlign: 'center'}}>Минем программалаштыруда беренче адымнарым.</Title>

												<Spacing size={16} />
												<Separator/>
												<Spacing size={48} />


												<SimpleCell multiline>
													<Spacing size={4} />
													<Title style={{display: 'flex'}}><Avatar size={34} src='https://i.imgur.com/fYYi5eW.jpeg'/>&nbsp;Команда үзәге</Title>
													<span style={chip_style}>2017, җәй</span>
													<Spacing size={10} />
													<div style={{display: "flex"}}>
														<Button
															onClick={() => {redirecting_toast(); setTimeout(() => {window.open("https://github.com/hikariatama/hikariatama/blob/master/archive_command_centre.lua", "_blank");}, 1000);}}
															appearance='accent'
															before={git}
															size='m'>Lua</Button>

														<Button
															onClick={() => {redirecting_toast(); setTimeout(() => {window.open("https://computercraft.ru/topic/2308-lesorub-fermer-panel-upravleniya-chatbot", "_blank");}, 1000);}}
															appearance='accent'
															mode='outline'
															size='m'
															style={{marginLeft: 8}}>Форум</Button>
													</div>
												</SimpleCell>

												<Spacing size={16} />

												<SimpleCell multiline>
													<Spacing size={4} />
													<Title style={{display: 'flex'}}><Avatar size={34} src='https://i.imgur.com/fYYi5eW.jpeg'/>&nbsp;Chat Бот</Title>
													<span style={chip_style}>2017, җәй</span>
													<Spacing size={10} />
													<Button
														onClick={() => {redirecting_toast(); setTimeout(() => {window.open("https://github.com/hikariatama/hikariatama/blob/master/archive_chatbot.lua", "_blank");}, 1000);}}
														appearance='accent'
														before={git}
														size='m'>Lua</Button>
												</SimpleCell>

												<Spacing size={16} />

												<SimpleCell multiline>
													<Spacing size={4} />
													<Title style={{display: 'flex'}}><Avatar size={34} src='https://i.imgur.com/fYYi5eW.jpeg'/>&nbsp;3D матбугат</Title>
													<span style={chip_style}>2017, җәй</span>
													<Spacing size={10} />
													<Button
														onClick={() => {redirecting_toast(); setTimeout(() => {window.open("https://github.com/hikariatama/hikariatama/blob/master/archive_3dprint.lua", "_blank");}, 1000);}}
														appearance='accent'
														before={git}
														size='m'>Lua</Button>
												</SimpleCell>


												<Spacing size={16} />

												<SimpleCell multiline>
													<Spacing size={4} />
													<Title style={{display: 'flex'}}><Avatar size={34} src='https://i.imgur.com/fYYi5eW.jpeg'/>&nbsp;Мәгълүмат панеле</Title>
													<span style={chip_style}>2017, җәй</span>
													<Spacing size={10} />
													<Button
														onClick={() => {redirecting_toast(); setTimeout(() => {window.open("https://github.com/hikariatama/hikariatama/blob/master/archive_infopanel.lua", "_blank");}, 1000);}}
														appearance='accent'
														before={git}
														size='m'>Lua</Button>
												</SimpleCell>

												<Spacing size={16} />

												<SimpleCell multiline>
													<Spacing size={4} />
													<Title style={{display: 'flex'}}><Avatar size={34} src='https://i.imgur.com/fYYi5eW.jpeg'/>&nbsp;Дәрес</Title>
													<span style={chip_style}>2017, җәй</span>
													<Spacing size={10} />
													<Button
														onClick={() => {redirecting_toast(); setTimeout(() => {window.open("https://github.com/hikariatama/hikariatama/blob/master/archive_lessons.lua", "_blank");}, 1000);}}
														appearance='accent'
														before={git}
														size='m'>Lua</Button>
												</SimpleCell>

												<Spacing size={16} />

												<SimpleCell multiline>
													<Spacing size={4} />
													<Title style={{display: 'flex'}}><Avatar size={34} src='https://i.imgur.com/fYYi5eW.jpeg'/>&nbsp;Touch-ID</Title>
													<span style={chip_style}>2017, җәй</span>
													<Spacing size={10} />
													<Button
														onClick={() => {redirecting_toast(); setTimeout(() => {window.open("https://github.com/hikariatama/hikariatama/blob/master/archive_touchid.lua", "_blank");}, 1000);}}
														appearance='accent'
														before={git}
														size='m'>Lua</Button>
												</SimpleCell>

												<Spacing size={16} />

												<SimpleCell multiline>
													<Spacing size={4} />
													<Title style={{display: 'flex'}}><Avatar size={34} src='https://i.imgur.com/fYYi5eW.jpeg'/>&nbsp;Чат укучысы</Title>
													<span style={chip_style}>2017, җәй</span>
													<Spacing size={10} />
													<Button
														onClick={() => {redirecting_toast(); setTimeout(() => {window.open("https://github.com/hikariatama/hikariatama/blob/master/archive_chat_reader.lua", "_blank");}, 1000);}}
														appearance='accent'
														before={git}
														size='m'>Lua</Button>
												</SimpleCell>
												</SplitCol>

											</div>
											<Spacing size={40}/>
										</Div>
									</Group>
								</Panel>

								<Panel id="contact">
									<Group>
										<Div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center', margin: 10}}>
											<h1 className="vkuiTitle vkuiTitle--android Title Title--android vkuiTitle--w-semibold Title--w-semibold vkuiTitle--l-1 Title--l-1"
												style={{marginTop: 16}}>
												Минем белән ничек элемтәгә керергә
											</h1>
											<Spacing size={32} />
											<Div>
												<div style={{display: "auto"}}>
													<Button
														onClick={() => {redirecting_toast(); setTimeout(() => {window.open("tg://resolve?domain=hikariatama", "_blank");}, 1000);}}
														appearance='neutral'
														size='m'
														before={tg}
														style={{background: '#3949ab', margin: 8}}>
														Телеграм
													</Button>
													
													<Button
														onClick={() => {redirecting_toast(); setTimeout(() => {window.open("https://discordapp.com/users/600334396104376341", "_blank");}, 1000);}}
														appearance='neutral'
														size='m'
														before={discord}
														style={{background: '#5e35b1', margin: 8}}>
														Дискорд
													</Button>

													<Button
														onClick={() => {redirecting_toast(); setTimeout(() => {window.open("mailto:me@hikariatama.ru", "_blank");}, 1000);}}
														appearance='neutral'
														size='m'
														before=<lord-icon
														    src="https://cdn.lordicon.com/pdpnqfoe.json"
														    trigger="loop"
														    style={{width: "28px", "height": "28px"}}>
														</lord-icon>
														style={{background: '#d81b60', margin: 8}}>
														Электрон почта
													</Button>

													<Button
														onClick={() => {redirecting_toast(); setTimeout(() => {window.open("https://vk.com/dan.innocoffee", "_blank");}, 1000);}}
														appearance='neutral'
														size='m'
														before={vk}
														style={{background: '#0d47a1', margin: 8}}>
														ВКонтакте
													</Button>
												</div>
											</Div>
											<Spacing size={16} />
											<Separator/>
											<Spacing size={16} />
											<Div>
												<div style={{display: "auto"}}>
													<Button
														onClick={() => {redirecting_toast(); setTimeout(() => {window.open("https://github.com/hikariatama", "_blank");}, 1000);}}
														appearance='neutral'
														size='m'
														before={git}
														style={{background: '#fff', color: '#000', margin: 8}}>
														Гитхаб
													</Button>

													<Button
														onClick={() => {redirecting_toast(); setTimeout(() => {window.open("https://instagram.com/hikariatama", "_blank");}, 1000);}}
														appearance='neutral'
														size='m'
														before=<lord-icon
														    src="https://cdn.lordicon.com/qierxeeb.json"
														    trigger="loop"
														    style={{width: "28px", "height": "28px"}}>
														</lord-icon>
														style={{background: '#ff6f00', margin: 8}}>
														Инстаграм
													</Button>

													<Button
														onClick={() => {redirecting_toast(); setTimeout(() => {window.open("https://open.spotify.com/user/e5fthmee85bk9qfu7zaekvjsb", "_blank");}, 1000);}}
														appearance='neutral'
														size='m'
														before=<lord-icon
														    src="https://assets5.lottiefiles.com/packages/lf20_v2gjaej7.json"
														    trigger="loop"
														    style={{width: "28px", "height": "28px"}}>
														</lord-icon>
														style={{background: '#27d966', margin: 8}}>
														Спотифай
													</Button>
												</div>
											</Div>
										</Div>
									</Group>
								</Panel>
							</View>
						</SplitCol>
						<Toaster/>
					</SplitLayout>
				</AppRoot>
			</AdaptivityProvider>
		</ConfigProvider>

	);
};

var resuming = false;
var current_sec = 0;
var current_length = 0;

function lead0(num) {
	return num < 10 ? '0' + num.toString() : num.toString();
}


function update_player() {
	document.querySelector('#player_play_or_pause').innerHTML = (resuming ? '<i class="gg gg-play-pause-o"></i>': '<i class="gg gg-play-button-o"></i>');
	if(current_sec > current_length) current_sec = current_length;
	document.querySelector('.bar_before_text').innerHTML = lead0(Math.floor(current_sec / 60)) + ":" + lead0(Math.ceil(current_sec % 60));
	document.querySelector('.bar_after_text').innerHTML = lead0(Math.floor(current_length / 60)) + ":" + lead0(Math.ceil(current_length % 60));

	if(current_length !== 0) 
		document.querySelector('.inner_bar_before').style.marginLeft = "-" + (100 - current_sec / current_length * 100).toString() + "%";

	if(resuming && !document.querySelector('#player').classList.contains('show')) document.querySelector('#player').classList.add('show');
	else if(!resuming && document.querySelector('#player').classList.contains('show')) document.querySelector('#player').classList.remove('show');

	if(!resuming) return;
	current_sec += 1;	
}

function cut(string) {
	if (string.length < 40) return string;
	return string.substr(0, 40) + "...";
}

function spotify_update() {
	fetch('https://spotify.hikariatama.ru')
		.then(response => response.json())
		.then(data => {
			if(!data) {
				document.querySelector('#player').style.display = 'none';
				return
			}

			if(data['actions']['disallows']['resuming'] !== undefined) {
				resuming = true;
			} else {
				resuming = false;
			}

			document.querySelector('#player_album_image').src = data['item']['album']['images'][0]['url'];
			var new_current = Math.round(data['progress_ms'] / 1000);
			if(Math.abs(new_current - current_sec) >= 5) current_sec = new_current;
			current_length = Math.round(data['item']['duration_ms'] / 1000);
			document.querySelector('#player_artist').innerHTML = cut(data['item']['name']);
			document.querySelector('#player_song').innerHTML = cut(data['item']['artists'][0]['name']);
			document.querySelector('#player_song_link').href = data['item']['external_urls']['spotify'];
		})
}

setInterval(update_player, 1000);
spotify_update();
setInterval(spotify_update, 5000);

ReactDOM.render(
	<div id="root_subitem">
		<div>
			<VkCard/>
		</div>
	</div>,
	document.getElementById("root")
);
